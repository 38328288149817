'use strict';

import u from './utility';

const document = u.getDocumentObject();

export default {
    getSrc: (regex) => {
        let source = '';
        const scripts = Array.prototype.slice.call(document.getElementsByTagName('script'));
        scripts.forEach(function (script) {
            let src = '';
            if (script.getAttribute.length !== undefined) {
                src = script.src;
            } else {
                src = script.getAttribute('src', -1);
            }
            if (src && src.match(regex)) {
                source = src;
            }
        });
        return source;
    }
}
