'use strict';

import u from './utility';
import map from './number-map';

const SWAP_ANY_NUMBER = '*';

/**
 * Performs a single regex replacement for strictly numeric phone numbers.
 * @param {string} numToFind
 * @param {string} numToInsert
 * @param {string} formattedNumber
 * @param {string} country Two letter country code.
 * @param {HTMLElement?} parentNode Optional parent element node for replacements.
 */
function replaceSingleRegexSwitch(numToFind, numToInsert, formattedNumber, country, parentNode = null) {
    switch (country) {
        case 'te':
            _replaceSingleRegexTest(numToFind, numToInsert, formattedNumber, parentNode);
            break;
        case 'GB':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '44', parentNode);
            break;
        case 'IE':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '353', parentNode);
            break;
        case 'AU':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '61', parentNode);
            break;
        case 'NZ':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '64', parentNode);
            break;
        case 'FR':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '33', parentNode);
            break;
        case 'DE':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '49', parentNode);
            break;
        case 'IT':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '39', parentNode);
            break;
        case 'CL':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '56', parentNode);
            break;
        case 'CH':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '41', parentNode);
            break;
        case 'HK':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '852', parentNode);
            break;
        case 'AR':
            _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, '54', parentNode);
            break;
        default:
            _replaceSingleRegex(numToFind, numToInsert, formattedNumber, parentNode);
            break;
    }
}

function _buildSingleNumberRegex(numToFindS) {
    const iterationS = [];
    const countryCode = '';
    let areaCode, exchange, remain, fullExpressionS;
    if (numToFindS === SWAP_ANY_NUMBER) {
        areaCode = '[2-9]\\d{2}';
        exchange = '\\d{3}';
        remain = '\\d{4}';
    } else {
        fullExpressionS = numToFindS.replace(/[.?*+^$[\]\\/(){}|-]/g, '\\$&');
        numToFindS = numToFindS.replace(/[\s.?*+^$[\]\\/(){}|-]/g, '');
        if (numToFindS.substring(0, 1) === '1') {
            numToFindS = numToFindS.substring(1);
        }
        areaCode = numToFindS.substring(0, 3);
        exchange = numToFindS.substring(3, 6).replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        remain = numToFindS.substring(6).replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    iterationS[0] = countryCode + '\\([\\s\\u00A0\\u00AD]*' + areaCode + '[\\s\\u00A0\\u00AD]*\\)[\\-\\.\\s\\u00A0\\u00AD]*' + exchange + '[\\-\\.\\s\\u00A0\\u00AD]*' + remain + '(?!\\d)(?=[<\\s\\u00A0\\u00AD\\.\\!\\?\\,]?)';
    iterationS[1] = countryCode + areaCode + '[\\-\\.\\s\\u00A0\\u00AD]*' + exchange + '[\\-\\.\\s\\u00A0\\u00AD]*' + remain + '(?!\\d)(?=[<\\s\\u00A0\\u00AD\\.\\!\\?\\,]?)';
    iterationS[2] = countryCode + '(' + areaCode + ')[\\u00A0\\u00AD\\s]*(' + exchange + ')[\u00A0\u00AD\\s]*(' + remain + ')(?!\\d)(?=[<\\s\\u00A0\\u00AD\\.\\!\\?\\,]?)';
    const iCountS = iterationS.length - 1;
    for (let i = 0; i <= iCountS; i++) {
        fullExpressionS = `${fullExpressionS}|${iterationS[i]}`;
    }

    return new RegExp(fullExpressionS, 'gi');
}

function _buildSingleNumberRegexUK(numToFindS, code) {
    let fullExpressionS = numToFindS.replace(/[.?*+^$[\]\\/(){}|-]/g, '\\$&');
    let start = 0;
    numToFindS = numToFindS.replace(/[\s.?*+^$[\]\\/(){}|-\u00A0|-\u00AD]/g, '');
    if (numToFindS.substring(0, code.length) == code) {
        start = code.length;
    }
    if (numToFindS.substring(0, code.length + 1) == '+' + code){
        start = code.length + 1;
    }

    let stringFormat = '\\([\\u00A0\\u00AD\\s]*';
    let stringFormat2 = ' ?\\)?[\\-\\.\\u00A0\\u00AD\\s]*';
    let stringFormat3 = '[\\-\\.\\u00A0\\u00AD\\s]*';
    let stringFormat4 = '(?!\\d)';

    let areaCode1 = numToFindS.substring(start, start+1);
    let areaCode2 = numToFindS.substring(start, start+2);
    let areaCode3 = numToFindS.substring(start, start+3);
    let areaCode4 = numToFindS.substring(start, start+4);
    let exchange1_3 = numToFindS.substring(start+1, start+4);
    let exchange2_4 = numToFindS.substring(start+2, start+6);
    let exchange2_3 = numToFindS.substring(start+2, start+5);
    let exchange3_3 = numToFindS.substring(start+3, start+6);
    let exchange4_6 = numToFindS.substring(start+4, start+10);
    let exchange3_2 = numToFindS.substring(start+3, start+5);
    let exchange3_6 = numToFindS.substring(start+3, start+9);
    let exchange4_5 = numToFindS.substring(start+4, start+9);
    let exchange4_3 = numToFindS.substring(start+4, start+7);
    let remain3 = numToFindS.substring(start+3);
    let remain4 = numToFindS.substring(start+4);
    let remain5 = numToFindS.substring(start+5);
    let remain6 = numToFindS.substring(start+6);
    let remain7 = numToFindS.substring(start+7);
    if (numToFindS.length == (start + 8)) {
        //1-3-4
        fullExpressionS += '|' + stringFormat + areaCode1 + stringFormat2 + exchange1_3 + stringFormat3 + remain4 + stringFormat4;
        fullExpressionS += '|' + areaCode1 + stringFormat2 + exchange1_3 + stringFormat3 + remain4 + stringFormat4;
        //2-3-3
        fullExpressionS += '|' + stringFormat + areaCode2 + stringFormat2 + exchange2_3 + stringFormat3 + remain5 + stringFormat4;
        fullExpressionS += '|' + areaCode2 + stringFormat2 + exchange2_3 + stringFormat3 + remain5 + stringFormat4;
        //3-5
        fullExpressionS += '|' + stringFormat + areaCode3 + stringFormat2 + remain3 + stringFormat4;
        fullExpressionS += '|' + areaCode3 + stringFormat2 + remain3 + stringFormat4;
        //4-4
        fullExpressionS += '|' + stringFormat + areaCode4 + stringFormat2 + remain4 + stringFormat4;
        fullExpressionS += '|' + areaCode4 + stringFormat2 + remain4 + stringFormat4;
    } else if (numToFindS.length == (start + 9)) {
        //1-2-2-2-2
        fullExpressionS += '|' + stringFormat + areaCode1 + stringFormat2 + numToFindS.substring(start+1, start+3) + stringFormat3 + numToFindS.substring(start+3, start+5) + stringFormat3 + numToFindS.substring(start+5, start+7) + stringFormat3 + numToFindS.substring(start+7) + stringFormat4;
        fullExpressionS += '|' + areaCode1 + stringFormat2 + numToFindS.substring(start+1, start+3) + stringFormat3 + numToFindS.substring(start+3, start+5) + stringFormat3 + numToFindS.substring(start+5, start+7) + stringFormat3 + numToFindS.substring(start+7) + stringFormat4;
        //2-3-2-2
        fullExpressionS += '|' + stringFormat + areaCode2 + stringFormat2 + numToFindS.substring(start+2, start+5) + stringFormat3 + numToFindS.substring(start+5, start+7) + stringFormat3 + numToFindS.substring(start+7) + stringFormat4;
        fullExpressionS += '|' + areaCode2 + stringFormat2 + numToFindS.substring(start+2, start+5) + stringFormat3 + numToFindS.substring(start+5, start+7) + stringFormat3 + numToFindS.substring(start+7) + stringFormat4;
        //2-3-4
        fullExpressionS += '|' + stringFormat + areaCode2 + stringFormat2 + exchange2_3 + stringFormat3 + remain5 + stringFormat4;
        fullExpressionS += '|' + areaCode2 + stringFormat2 + exchange2_3 + stringFormat3 + remain5 + stringFormat4;
        //3-2-4
        fullExpressionS += '|' + stringFormat + areaCode3 + stringFormat2 + exchange3_2 + stringFormat3 + remain5 + stringFormat4;
        fullExpressionS += '|' + areaCode3 + stringFormat2 + exchange3_2 + stringFormat3 + remain5 + stringFormat4;
        //3-3-3
        fullExpressionS += '|' + stringFormat + areaCode3 + stringFormat2 + exchange3_3 + stringFormat3 + remain6 + stringFormat4;
        fullExpressionS += '|' + areaCode3 + stringFormat2 + exchange3_3 + stringFormat3 + remain6 + stringFormat4;
        //3-6
        fullExpressionS += '|' + stringFormat + areaCode3 + stringFormat2 + remain3 + stringFormat4;
        fullExpressionS += '|' + areaCode3 + stringFormat2 + remain3 + stringFormat4;
        //4-5
        fullExpressionS += '|' + stringFormat + areaCode4 + stringFormat2 + remain4 + stringFormat4;
        fullExpressionS += '|' + areaCode4 + stringFormat2 + remain4 + stringFormat4;
    }
    //10 digit numbers
    else {
        let optionalZero = '';
        if (code === '44') {
            optionalZero = '0?'
        }
        //2-2-2-2-2
        fullExpressionS += '|' + stringFormat + optionalZero + areaCode2 + stringFormat2 + numToFindS.substring(start+2, start+4) + stringFormat3 + numToFindS.substring(start+4, start+6) + stringFormat3 + numToFindS.substring(start+6, start+8) + stringFormat3 + numToFindS.substring(start+8) + stringFormat4;
        fullExpressionS += '|' + optionalZero + areaCode2 + stringFormat2 + numToFindS.substring(start+2, start+4) + stringFormat3 + numToFindS.substring(start+4, start+6) + stringFormat3 + numToFindS.substring(start+6, start+8) + stringFormat3 + numToFindS.substring(start+8) + stringFormat4;
        //2-4-4
        fullExpressionS += '|' + stringFormat + optionalZero + areaCode2 + stringFormat2 + exchange2_4 + stringFormat3 + remain6 + stringFormat4;
        fullExpressionS += '|' + optionalZero + areaCode2 + stringFormat2 + exchange2_4 + stringFormat3 + remain6 + stringFormat4;
        //2-3-5
        fullExpressionS += '|' + stringFormat + optionalZero + areaCode2 + stringFormat2 + exchange2_3 + stringFormat3 + remain5 + stringFormat4;
        fullExpressionS += '|' + optionalZero + areaCode2 + stringFormat2 + exchange2_3 + stringFormat3 + remain5 + stringFormat4;
        //3-3-4
        fullExpressionS += '|' + stringFormat + optionalZero + areaCode3 + stringFormat2 + exchange3_3 + stringFormat3 + remain6 + stringFormat4;
        fullExpressionS += '|' + optionalZero + areaCode3 + stringFormat2 + exchange3_3 + stringFormat3 + remain6 + stringFormat4;
        //4-6
        fullExpressionS += '|' + stringFormat + optionalZero + areaCode4 + stringFormat2 + remain4 + stringFormat4;
        fullExpressionS += '|' + optionalZero + areaCode4 + stringFormat2 + exchange4_6 + stringFormat4;
        //4-3-3
        fullExpressionS += '|' + stringFormat + optionalZero + areaCode4 + stringFormat2 + exchange4_3 + stringFormat3 + remain7 + stringFormat4;
        fullExpressionS += '|' + optionalZero + areaCode4 + stringFormat2 + exchange4_3 + stringFormat3 + remain7 + stringFormat4;
    }

    fullExpressionS += '|' + numToFindS.substring(start);

    return new RegExp(fullExpressionS, 'gi');
}

/**
 * Performs a single regex replacement for a test country.
 * @param {string} numToFind
 * @param {string} numToInsert
 * @param {string} formattedNumber
 * @param {HTMLElement?} parentNode Optional parent element node for replacements.
 * @private
 */
function _replaceSingleRegexTest(numToFind, numToInsert, formattedNumber, parentNode) {
    if (numToFind.length === 0) {
        return;
    }
    _domIterator(numToFind, numToInsert, formattedNumber, parentNode);
}

/**
 * Performs a single regex replacement for strictly numeric phone numbers.
 * @param {string} numToFind
 * @param {string} numToInsert
 * @param {string} formattedNumber
 * @param {HTMLElement?} parentNode Optional parent element node for replacements.
 * @private
 */
function _replaceSingleRegex(numToFind, numToInsert, formattedNumber, parentNode) {
    numToFind = numToFind.trim();
    if (numToFind.length === 0) {
        return;
    }
    let rregex = _buildSingleNumberRegex(numToFind);
    if (formattedNumber.substring(0, 2) === '1 ') {
        formattedNumber = formattedNumber.substring(2);
    } else if (formattedNumber.substring(0, 1) === '1') {
        formattedNumber = formattedNumber.substring(1);
    }
    if (numToInsert.substring(0, 1) === '+') {
        numToInsert = numToInsert.substring(1);
    }

    _domIterator(rregex, numToInsert, formattedNumber, parentNode);
}

/**
 * Performs a single regex replacement for a given country.
 * @param {string} numToFind
 * @param {string} numToInsert
 * @param {string} formattedNumber
 * @param {number|string} code Country code.
 * @param {HTMLElement?} parentNode Optional parent element node for replacements.
 * @private
 */
function _replaceSingleRegexAlt(numToFind, numToInsert, formattedNumber, code, parentNode) {
    if (numToFind === SWAP_ANY_NUMBER) {
        return;
    }
    numToFind = numToFind.trim();
    if (numToFind.length === 0) {
        return;
    }
    let rregex = _buildSingleNumberRegexUK(numToFind, code);
    if (formattedNumber.substring(0, code.length + 1) === code + ' ') {
        formattedNumber = formattedNumber.substring(code.length + 1);
    }
    else if (formattedNumber.substring(0, code.length) === code) {
        formattedNumber = formattedNumber.substring(code.length);
    }
    if (numToInsert.substring(0, 1) === '+') {
        numToInsert = numToInsert.substring(1);
    }

    _domIterator(rregex, numToInsert, formattedNumber, parentNode);
}

function _domIterator(regex, replacementNumber, formattedNumber, node) {
    const childNodes = (node || document.body).childNodes;
    let cnLength = childNodes.length;
    const excludes = ['html', 'head', 'style', 'link', 'title', 'meta', 'script', 'iframe'];
    let numId = null;

    while (cnLength--) {
        let currentNode = childNodes[cnLength];
        if (currentNode.nodeType === Node.ELEMENT_NODE) {
            if (currentNode.nodeName === 'A' && (currentNode.href.match(/^tel:/) || currentNode.href.match(/\/tel\//))) {
                let pot = currentNode.href.replace(regex, replacementNumber);
                if (pot !== currentNode.href){
                    if (currentNode.href.match(/^tel:/)) {
                        currentNode.href = 'tel:+' + replacementNumber;
                    } else if (currentNode.href.match(/\/tel\//)) {
                        currentNode.href =  currentNode.href.replace(/tel\/\+\d{7,16}/i, 'tel/+' + replacementNumber);
                        currentNode.href =  currentNode.href.replace(/tel\/\d{7,16}/i, 'tel/+' + replacementNumber);
                    }
                    numId = map.addNumber(null, replacementNumber);
                    if (numId) {
                        currentNode.setAttribute(u.trackingNumberAttributeName, numId);
                        currentNode.classList.add(u.trackingNumberElIdentifier);
                    }
                }
            }
            if (excludes.indexOf(currentNode.nodeName.toLowerCase()) === -1 && currentNode.getAttribute('ninjatrack_ignore') == null) {
                _domIterator(regex, replacementNumber, formattedNumber, currentNode);
            }
        }
        if (currentNode == null || currentNode.nodeType !== Node.TEXT_NODE || currentNode.data === undefined) {
            continue;
        }
        // a match was found if still here
        // assign the replaced value

        let newText = currentNode.data.replace(regex, formattedNumber);
        // only replace if a change was made
        // and replace only the data of the current node.
        if (newText !== currentNode.data) {
            const parentV = currentNode.parentElement != null ? currentNode.parentElement : currentNode.parentNode;
            if (parentV != null && !parentV.getAttribute('ninjatrack_orig')) {
                parentV.setAttribute('ninjatrack_orig', encodeURIComponent(currentNode.data));
                if (numId) {
                    map.updateNumberInnerHtml(numId, newText);
                } else {
                    numId = map.addNumber(newText, replacementNumber);
                }
                if (numId) {
                    parentV.setAttribute(u.trackingNumberAttributeName, numId);
                    parentV.classList.add(u.trackingNumberElIdentifier);
                }
            }
            currentNode.data = newText;
            parentV.setAttribute('ninjatrack_timestamp', new Date().getTime())
        }
    }
}

/**
 * Performs replacements
 * @param {[SwapData]} swapDataList
 * @param {HTMLElement?} parentNode Optional parent element node for replacements.
 * @return {Promise<{replacedCount:number, notReplacedCount:number}>}
 */
async function replaceAll(swapDataList, parentNode = null) {
    let replacedCount = 0;
    let notReplacedCount = 0;
    await Promise.all(swapDataList.map((swapData) => {
        if (swapData.hasNotExpired()) {
            replaceSingleRegexSwitch(swapData.numToFind, swapData.numToInsert, swapData.formattedNumber, swapData.country, parentNode);
            replacedCount++;
        } else {
            notReplacedCount++;
        }
    }));
    return {replacedCount, notReplacedCount};
}

export default {
    replace: replaceSingleRegexSwitch,
    replaceAll,
}
