'use strict';

import axios from 'axios';
import * as cookieJar from 'es-cookie';
import multiCookieMgr from './multi-cookie-manager';
import singleCookieMgr from './single-cookie-manager';
import loadScript from 'load-script2';
import params from './url-params';
import sourcer from './script-sourcer';
import util from './utility';

/**
 * Inspects current URL and cookies for TruMeasure IDs and makes a request to our DNI mapping endpoint in order to
 * determine which version of the DNI script to load into the browser.
 * @param {string} hermesDniPath URL Path to Hermes DNI script from root.
 * @param {{getWindowObject: function}|null} [mockUtil] Optional mock utility to inject which provides a fake window object.
 * @return {Promise<void>}
 */
export default async (hermesDniPath, mockUtil = null) => {
    // derive the full endpoint URL on the same host as this script
    const scriptSrc = sourcer.getSrc(/[sS]cripts\/(TML|tml)oader\.js/);
    const a = document.createElement('a');
    a.href = scriptSrc;
    const urlRoot = scriptSrc.substring(0, scriptSrc.search(/[sS]cripts\/(TML|tml)oader\.js/));

    if (singleCookieMgr.get(singleCookieMgr.hermesCookieName) || multiCookieMgr.getAnalyticsID()) {
        // with the existence of the hermes cookie, jump straight to loading the hermes DNI script
        await loadScript(urlRoot + hermesDniPath);
        return;
    }

    const paramName = 'tmad';
    const pathPattern = new RegExp('\\/tmad\\/([a-z0-9]{12})(?=\\/)?', 'i');
    const legacyParamName = 'upu_uid';
    const cookieName = 'tmad';
    const legacyCookieName = 'upu_uid';

    // grab the window location object
    const location = (mockUtil ?? util).getWindowObject().location;

    // derive the id if available by this order of precedence.
    const id = params.getVar(paramName, location.href, null)
        ?? location.pathname.match(pathPattern)?.[1]
        ?? params.getVar(legacyParamName, location.href, null)
        ?? cookieJar.get(cookieName)
        ?? cookieJar.get(legacyCookieName)
    ;

    const response = await axios.get(`${urlRoot}/dni-trumeasure`, {params: {id}});
    await loadScript(response.data.src);
}