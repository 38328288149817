'use strict';

export default {
    getVar: (name, url, defaultVal = '') => {
        const searchUrl = url;
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        const regexS = "[\\?&]" + name + "=([^&#]*)";
        const regex = new RegExp(regexS, 'i');
        const results = regex.exec(searchUrl);
        if (results == null) {
            return defaultVal;
        } else {
            return results[1];
        }
    }
};
