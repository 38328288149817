import SwapData from './SwapData';

/** @deprecated Use SwapData instead */
export default class SingleCookieSwapData extends SwapData {
    /**
     * Unserializes individual number swap data segment of a single cookie.
     * @param {string} serialized
     * @returns {SingleCookieSwapData}
     */
    static unserialize(serialized) {
        const parts = serialized.split('|');
        let expiry = null;
        if (parts.length > 6) {
            expiry = new Date(parseInt(parts[6]) * 1000);
        }
        return new SingleCookieSwapData(parts[0], parts[1], parts[2], parts[3], parseInt(parts[4]), '1' === parts[5], expiry);
    }

    /**
     * @param {string} numToFind
     * @param {string} numToInsert
     * @param {string} formattedNumber
     * @param {string} country
     * @param {number} analyticsID
     * @param {boolean} checkServerForReplace
     * @param {Date} expiry
     */
    constructor(numToFind, numToInsert, formattedNumber, country, analyticsID, checkServerForReplace, expiry) {
        super(numToFind, numToInsert, formattedNumber, country, checkServerForReplace, expiry);
        this.analyticsID = analyticsID;
    }
}
