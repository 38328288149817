'use strict';

import * as storage from 'es-cookie';
import psl from 'psl';
import singleCookieMgr from './single-cookie-manager';
import SwapData from './SwapData';

const swapDataNamePattern = new RegExp(/^nt_hermes_swap_([\d]+)$/);

/**
 * Provides cookie value from given name.
 * @param {string} name Name of cookie.
 * @param {*?} defaultVal Optional default value if cookie not found. Defaults to null.
 * @returns {*}
 */
function get(name, defaultVal = null) {
    const val = storage.get(name);
    if (typeof val === 'undefined') {
        return defaultVal;
    }
    return val;
}

/**
 * Retrieves SwapData list from storage if available.
 * @returns {[SwapData]}
 */
function getSwapDataList() {
    let swapDataList = [];
    for (const [cookieName, cookieVal] of Object.entries(storage.getAll())) {
        let cookieNameMatches = cookieName.match(swapDataNamePattern);
        if (cookieNameMatches) {
            swapDataList[cookieNameMatches[1]] = SwapData.unserialize(cookieVal);
        }
    }
    return swapDataList;
}

/**
 * Removes all SwapData from storage.
 */
function clearSwapDataList() {
    Object.keys(storage.getAll()).forEach((name) => {
        if (swapDataNamePattern.test(name)) {
            storage.remove(name);
        }
    });
}

/**
 * Writes SwapData into storage.
 * @param {number} index
 * @param {SwapData} swapData
 * @param {Date} expires
 */
function storeSwapData(index, swapData, expires) {
    storage.set(`nt_hermes_swap_${index}`, swapData.serialize(), {expires, sameSite: 'lax', domain: getBaseDomain()});
}

/**
 * Retrieves analytics ID from storage if available.
 * @returns {number|null}
 */
function getAnalyticsID() {
    let val = storage.get('nt_hermes_analytics');
    return val ? parseInt(val) : null;
}

/**
 * Writes analytics ID into storage.
 * @param {number} analyticsID
 * @param {Date} expires
 */
function storeAnalyticsID(analyticsID, expires) {
    storage.set('nt_hermes_analytics', `${analyticsID}`, {expires, sameSite: 'lax', domain: getBaseDomain()});
}

/**
 * Migrates SwapData from cookie(s).
 * @param {string} singleCookieName Name of the single cookie.
 */
function migrate(singleCookieName) {
    const singleCookie = storage.get(singleCookieName);
    if (!singleCookie) {
        // nothing to migrate
        return;
    }

    const swapDataList = singleCookieMgr.unserialize(singleCookie);
    const analyticsID = swapDataList[0]?.analyticsID;
    let expiry = new Date();
    expiry.setDate(expiry.getDate() + 30); // assume 30 day expiry
    swapDataList.forEach((swapData, index) => storeSwapData(index, swapData, expiry));
    storeAnalyticsID(analyticsID, expiry);
    storage.remove(singleCookieName);
}

/**
 * Retrieves the base domain from location.hostname (if it exists).
 * @returns {string|null}
 */
function getBaseDomain() {
  if(location && location.hostname) {
    const parsed = psl.parse(location.hostname);
    return parsed.domain ? parsed.domain : null;
  }

  return null;
}

export default {
    get,
    getSwapDataList,
    clearSwapDataList,
    storeSwapData,
    getAnalyticsID,
    storeAnalyticsID,
    migrate,
    getBaseDomain
};
