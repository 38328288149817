'use strict';

const version = 1;
const numberMap = {
    v: version,
    items: {}
};

function _getMap() {
    return numberMap;
}

function getItems() {
    return _getMap().items;
}

function getItemsCount() {
    return Object.keys(getItems()).length;
}

function clearItems() {
    const map = _getMap();
    map.items = {};
    return getItems();
}

function addNumber(innerHtml, number) {
    const map = _getMap();
    const id = _generateUniqueId(10);
    map.items[id] = {
        id: id,
        href: number.replace('+', ''),
        innerHtml: innerHtml
    };
    return id;
}

function updateNumberInnerHtml(numId, innerHtml) {
    const map = _getMap();
    map.items[numId].innerHtml = innerHtml;
    return map.items[numId].innerHtml === innerHtml;
}

function hasNumber(number) {
    return !!getIdForNumber(number);
}

function getIdForNumber(number) {
    const items = getItems();
    return Object.keys(items).find(key => items[key].href === number);
}

function getRandomId() {
    const keys = Object.keys(getItems());
    return keys.length ? keys[Math.floor(Math.random() * keys.length)] : null;
}

function getNumberData(numberId) {
    return getItems()[numberId]
}

function _generateUniqueId(length) {
    const items = getItems();
    let id = null;
    while (!id) {
        const tempId = _generateId(length);
        if (!items.hasOwnProperty(tempId)) {
            id = tempId;
        }
    }
    return id;
}

function _generateId(length) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsLen = chars.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charsLen));
    }
    return result;
}

export default {
    getItems: getItems,
    getItemsCount: getItemsCount,
    clearItems: clearItems,
    hasNumber: hasNumber,
    addNumber: addNumber,
    updateNumberInnerHtml: updateNumberInnerHtml,
    getNumberData: getNumberData,
    getIdForNumber: getIdForNumber,
    getRandomId: getRandomId
}
