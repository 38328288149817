'use strict';

import replacer from './number-replacer';
import u from './utility';
import SingleCookieSwapData from "./SingleCookieSwapData";

const document = u.getDocumentObject();

let analyticsID = null;

function setCookie(name, value, ttl, counter) {
    if (counter === 1) {
        let i=0,domain=document.domain,p=domain.split('.');
        let escV = escape(value);
        if (p.length >1) {
            while (i < (p.length - 1) && document.cookie.indexOf(name + '=' + escV) == -1) {
                domain = p.slice(-1 - (++i)).join('.');
                _setCookieInternal(name, escV, ttl, domain);
            }
        } else {
            _setCookieInternal(name,escV,ttl,null);
        }
    } else {
        let cur = getCookie(name, '');
        cur = cur + "{" + value;
        setCookie(name, cur, ttl, 1);
    }
}

function _setCookieInternal(name, value, ttl, domain) {
    let cookie = name + "=" + value + ";path=/";
    if (domain) {
        cookie += ";domain=" + domain;
    }
    if (ttl) {
        let tempDate = new Date();
        tempDate.setDate(tempDate.getDate() + ttl);
        cookie += ";expires=" + tempDate.toUTCString();
    }
    document.cookie = cookie;
}

function getCookie(name, default_value) {
    let get_cookie_ret_one;
    if (document.cookie) {
        let cookie_index = document.cookie.indexOf(name);
        if (cookie_index !== -1) {
            let namestart = (document.cookie.indexOf("=", cookie_index) + 1);
            let nameend = document.cookie.indexOf(";", cookie_index);
            if (nameend === -1) {
                nameend = document.cookie.length;
            }
            get_cookie_ret_one = document.cookie.substring(namestart, nameend);
        } else {
            get_cookie_ret_one = default_value;
        }
    } else {
        get_cookie_ret_one = default_value;
    }
    return get_cookie_ret_one ? unescape(get_cookie_ret_one) : default_value;
}

function parseCookie(cookie) {
    const swapDataList = unserialize(cookie);
    let replacedCount = 0;
    let notReplacedCount = 0;
    swapDataList.forEach(swapData => {
        if (swapData.hasNotExpired()) {
            replacer.replace(swapData.numToFind, swapData.numToInsert, swapData.formattedNumber, swapData.country);
            replacedCount++;
        } else {
            analyticsID = swapData.analyticsID;
            notReplacedCount++;
        }
    });
    return 0 < replacedCount && 0 === notReplacedCount;
}

/**
 * Unserializes swap data from cookie string.
 * @param {string} cookie Contents of cookie.
 * @returns {[SingleCookieSwapData]}
 */
function unserialize(cookie) {
    return cookie.split('{').map(numSerial => SingleCookieSwapData.unserialize(numSerial));
}

export default {
    /** @deprecated Use multi-cookie-manager instead */
    set: setCookie,
    /** @deprecated Use multi-cookie-manager instead  */
    parse: parseCookie,
    /** @deprecated Use multi-cookie-manager instead */
    analyticsID: analyticsID,
    /** @deprecated Use multi-cookie-manager instead */
    get: getCookie,
    /** @deprecated Use multi-cookie-manager instead */
    hermesCookieName: 'nt_hermes',
    unserialize,
}
