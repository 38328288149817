'use strict';

/**
 * Provides Window object.
 * @returns {Window}
 */
function getWindowObject() {
    return window;
}

/**
 * Provides Document object.
 * @returns {Document}
 */
function getDocumentObject() {
    return document;
}

export default {
    getWindowObject: getWindowObject,
    getDocumentObject: getDocumentObject,
    trackingNumberElIdentifier: 'nt-tracking-number',
    trackingNumberAttributeName: 'nt-tracking-number-id'
};
