export default class SwapData {
    /**
     * Unserializes a serialized SwapData instance.
     * @param {string} serialized
     * @returns {SwapData}
     */
    static unserialize(serialized) {
        let parsed = JSON.parse(serialized);
        return new SwapData(
            parsed.numToFind,
            parsed.numToInsert,
            parsed.formattedNumber,
            parsed.country,
            parsed.checkServerForReplace,
            parsed.expiry ? new Date(parsed.expiry) : null,
            parsed.cachedReplacementDataUUID,
        );
    }

    /**
     * @param {string} numToFind
     * @param {string} numToInsert
     * @param {string} formattedNumber
     * @param {string} country
     * @param {boolean} checkServerForReplace
     * @param {Date?} expiry
     * @param {string} cachedReplacementDataUUID
     */
    constructor(numToFind, numToInsert, formattedNumber, country, checkServerForReplace, expiry, cachedReplacementDataUUID) {
        this.numToFind = numToFind;
        this.numToInsert = numToInsert;
        this.formattedNumber = formattedNumber;
        this.country = country;
        this.checkServerForReplace = checkServerForReplace;
        this.expiry = expiry;
        this.cachedReplacementDataUUID = cachedReplacementDataUUID;
    }

    /**
     * Provides whether this swap data has not expired.
     * @returns {boolean}
     */
    hasNotExpired() {
        return this.expiry && this.expiry > new Date();
    }

    /**
     * Serializes ths instance.
     * @returns {string}
     */
    serialize() {
        return JSON.stringify({
            numToFind: this.numToFind,
            numToInsert: this.numToInsert,
            formattedNumber: this.formattedNumber,
            country: this.country,
            checkServerForReplace: this.checkServerForReplace,
            expiry: this.expiry,
            cachedReplacementDataUUID: this.cachedReplacementDataUUID,
        });
    }
}
